<template>
  <div>
    <template v-if="error">
      <v-container>
        <v-alert dense type="error">
          {{ error }}
          <v-btn @click="destroy" small class="float-right">
            Tentar novamente
          </v-btn>
        </v-alert>
      </v-container>
    </template>
    <template v-else-if="loading">
      <div
        class="d-flex flex-grow-1 justify-center align-center w-full h-full pa-4"
      >
        <v-progress-circular
          indeterminate
          size="50"
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
    <div class="h-full w-full" v-show="!loading && !error">
      <qrcode-stream
        v-if="scannerType == 'qrcode' && !destroyed && !error"
        @decode="($event) => decode('QR_CODE', $event)"
        :track="paintOutline"
        @init="initQRcode"
        :camera="!!paused ? 'off' : camera"
        :torch="torch"
        style="width: 100%; height: 100%"
      />
      <div
        style="
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100vh;
          height: 100dvh;
        "
        class="d-flex align-center justify-center white--text"
        v-if="scannerType === 'nfc'"
      >
        <div
          v-if="nfcProps.status == 'stopped'"
          class="d-flex flex-column align-center"
        >
          <v-icon size="80" class="pa-4" color="white">
            mdi-cellphone-nfc-off
          </v-icon>
          <span> Leitor Desativado </span>
        </div>
        <div
          v-else-if="nfcProps.status == 'starting'"
          class="d-flex flex-column align-center"
        >
          <v-icon size="80" class="pa-4" color="white">
            mdi-cellphone-nfc
          </v-icon>
          <span> Iniciando Leitor </span>
        </div>
        <div
          v-else-if="nfcProps.status == 'started'"
          class="d-flex flex-column align-center"
        >
          <v-icon size="80" class="pa-4" color="white">
            mdi-nfc-variant
          </v-icon>
          <span> Encoste o celular na pulseira ou cartão </span>
        </div>
      </div>

      <!-- Header -->
      <div
        style="position: absolute; top: 0; left: 0; right: 0"
        class="d-flex align-center gap-2 ma-4"
      >
        <v-btn-toggle
          v-model="scannerType"
          color="primary"
          small
          class="rounded-lg"
          v-if="availableScannerTypes.length > 1"
        >
          <v-btn
            v-for="t in availableScannerTypes"
            :key="t.value"
            :value="t.value"
          >
            {{ t.name }}
          </v-btn>
        </v-btn-toggle>
        <v-spacer />
        <template v-if="scannerType == 'qrcode'">
          <v-btn
            v-if="!error"
            :disabled="loading || !torchSupport"
            fab
            @click="torch = !torch"
            color="success"
            small
          >
            <v-icon>mdi-flashlight{{ torch ? "-off" : "" }}</v-icon>
          </v-btn>
          <v-btn
            v-if="!error"
            @click="toggleCamera"
            :disabled="loading"
            fab
            color="info"
          >
            <v-icon>mdi-camera-switch</v-icon>
          </v-btn>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { QrcodeStream } from "vue-qrcode-reader";
import isNFCCompatible from "@/utils/isNFCCompatible";
import md5 from "md5";
export default {
  props: {
    paused: {
      type: Boolean,
      default: false,
    },
    disallowMethods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Operational
      loading: false,
      error: false,
      scannerType: "none",

      // Camera
      destroyed: false,
      torch: false,
      torchSupport: false,
      camera: "rear",

      // NFC
      isNFCCompatible: isNFCCompatible(),
      nfcProps: {
        status: "stopped",
        message: "",
      },
      ndef: null,
    };
  },
  methods: {
    ...mapActions("organization", {
      updatePartyById: "partyById",
    }),
    decode(type, value) {
      this.$emit("scan", { type, value });
    },
    toggleCamera() {
      this.camera = this.camera === "rear" ? "front" : "rear";
    },
    destroy() {
      this.error = false;
      this.destroyed = true;
      this.$nextTick(() => {
        this.destroyed = false;
      });
    },
    async initQRcode(promise) {
      this.loading = true;
      this.error = false;

      try {
        const { capabilities } = await promise;

        this.torchSupport = !!capabilities.torch;
      } catch (error) {
        console.error(error);
        if (error.name == "OverconstrainedError") {
          this.toggleCamera();
        } else {
          this.error = "Não foi possível acessar a câmera";
        }
      } finally {
        this.loading = false;
      }
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    async prepareNFCReader() {
      try {
        const ndef = new window.NDEFReader();
        this.ndef = ndef;
        this.nfcProps.status = "starting";
        await ndef.scan();
        this.nfcProps.status = "started";
        ndef.onreading = this.nfcRead;
      } catch (error) {
        this.error = "Não foi possível iniciar o leitor de NFC";
        this.nfcProps.status = "stopped";
        this.$nextTick(() => {
          this.scannerType = "qrcode";
        });

        console.error(error);
      }
    },
    nfcRead({ serialNumber }) {
      if (this.scannerType !== "nfc") return;
      if (this.paused) return;
      this.decode("NFC", md5(serialNumber));
    },
  },

  computed: {
    availableScannerTypes() {
      const r = [{ name: "QR Code", value: "qrcode", icon: "mdi-qrcode" }];
      if (this.isNFCCompatible)
        r.push({ name: "NFC", value: "nfc", icon: "mdi-nfc" });

      return r.filter((t) => !this.disallowMethods.includes(t.value));
    },
  },
  watch: {
    scannerType: {
      immediate: true,
      handler(val) {
        if (val === "nfc") {
          if (!this.ndef) this.prepareNFCReader();
          else this.nfcProps.status = "started";
        } else {
          this.nfcProps.status = "stopped";
        }
      },
    },
  },
  mounted() {
    try {
      if (this.isNFCCompatible && !this.disallowMethods.includes("nfc"))
        this.scannerType = "nfc";
      else this.scannerType = "qrcode";
    } catch (e) {
      console.error(e);
      this.scannerType = "qrcode";
    }
  },
  components: {
    QrcodeStream,
  },
};
</script>
