<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-text class="pa-0 ma-0 pt-2">
        <vue-perfect-scrollbar
          :settings="{ suppressScrollX: true, wheelPropagation: false }"
          style="height: 100%"
        >
          <v-stepper v-model="step" class="elevation-0">
            <v-stepper-header class="elevation-0 mb-2" style="height: 50px">
              <template v-for="(stp, i) in steps">
                <v-divider v-if="i" :key="`divider-` + i" />
                <v-stepper-step
                  :key="`step-` + i"
                  :complete="step > i + 1"
                  :step="i + 1"
                  class="py-0"
                >
                  {{ stp }}
                </v-stepper-step>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="pt-0">
                <div style="position: relative">
                  <scanner
                    @scan="onScan"
                    :disallowMethods="['nfc']"
                    :paused="loading || step !== 1 || !isOpen"
                  />
                </div>
              </v-stepper-content>
              <v-stepper-content step="2" class="pt-0">
                <v-card
                  class="d-flex gap-4 align-center pa-2 mb-4"
                  outlined
                  rounded="lg"
                >
                  <v-img
                    v-if="device?.Model?.image"
                    :src="
                      (device?.acquirer &&
                        device?.Model?.imageVariants?.[device.acquirer]) ||
                      device?.Model?.image
                    "
                    max-height="55"
                    max-width="55"
                  />
                  <v-icon v-else color="primary" size="55">
                    mdi-cash-register
                  </v-icon>
                  <div>
                    <h6 class="mb-0">POS {{ device?.Model?.brand }}</h6>
                    SN: {{ device?.serialNumber }}
                  </div>
                </v-card>

                <place-search
                  v-model="address"
                  label="Localização"
                  outlined
                  class="mb-4"
                  :disabled="loading"
                ></place-search>
                <h6 class="mb-0">Formas de pagamento</h6>
                <div class="d-flex gap-4">
                  <v-checkbox
                    v-model="allowPaymentMethods"
                    label="Dinheiro"
                    value="money"
                  />
                  <v-checkbox
                    v-model="allowPaymentMethods"
                    label="PIX"
                    value="pix"
                  />
                  <v-checkbox
                    v-model="allowPaymentMethods"
                    label="Cartão de Crédito"
                    value="credit"
                  />
                  <v-checkbox
                    v-model="allowPaymentMethods"
                    label="Cartão de Débito"
                    value="debit"
                  />
                </div>

                <h6>Operadores</h6>
                <div v-for="(user, i) in users" :key="i" class="d-flex">
                  <phone-input
                    :phone="user.whatsapp"
                    @update:phone="(e) => setUsers(i, 'whatsapp', e)"
                    :ddi="user.ddi"
                    @update:ddi="(e) => setUsers(i, 'ddi', e)"
                    @valid="(v) => setUsers(i, 'valid', v)"
                    dense
                    outlined
                    class="flex-grow"
                    label="WhatsApp do operador"
                    :disabled="loading"
                  />
                  <v-btn v-if="i === 0" @click="addUser" class="ml-2" icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn v-else @click="removeUser(i)" class="ml-2" icon>
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </div>

                <v-alert color="primary" dense type="info" text>
                  Os operadores que ainda não possuem uma conta precisarão criar
                  uma para usar o POS.
                </v-alert>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <v-alert v-if="error" type="error" class="mx-6 mb-0">
            {{ error }}
          </v-alert>
        </vue-perfect-scrollbar>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1 justify-space-between">
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          v-if="step > 1"
          color="success"
          :disabled="loading || !users.every((u) => u.valid) || !address"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import Scanner from "../../../global/scanner/Scanner.vue";
import PlaceSearch from "../../../../views/global/PlaceSearch.vue";
import PhoneInput from "../../../global/PhoneInput.vue";

export default {
  components: {
    Scanner,
    PlaceSearch,
    PhoneInput,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    code: null,
    device: null,
    users: [],
    error: false,
    step: 1,
    address: null,
    allowPaymentMethods: ["money", "pix", "credit", "debit"],
  }),

  methods: {
    open(code) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.users = [{ whatsapp: "", ddi: 55, valid: false }];
      this.allowPaymentMethods = ["money", "pix", "credit", "debit"];
      this.address = null;
      if (code) {
        this.verifyCode(code);
      }
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.address = null;
      this.users = [{ whatsapp: "", ddi: 55, valid: false }];
      this.allowPaymentMethods = ["money", "pix", "credit", "debit"];
    },
    setUsers(index, key, value) {
      this.users[index][key] = value;
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },

    addUser() {
      this.users.push({ whatsapp: "", ddi: 55, valid: false });
    },
    async verifyCode(code) {
      try {
        this.loading = true;
        this.error = false;
        const { device } = await POS.adopt.verifyCode(
          this.selectedOrganization.id,
          code
        );

        this.code = code;
        this.device = device;
        this.step++;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    onScan({ value }) {
      const regex =
        /code=([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}:[0-9a-fA-F]{32})/;

      const match = value.match(regex);
      if (match && match[1]) {
        const code = match[1];
        this.verifyCode(code);
      } else {
        // Se nenhuma correspondência for encontrada, uma mensagem é exibida
        alert("Código não encontrado ou inválido.");
      }
    },

    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;

        const users = this.users.map((u) => ({
          ddi: u.ddi,
          phone: u.whatsapp,
        }));

        await POS.adopt.adopt(orgId, {
          code: this.code,
          address: this.address,
          users,
          allowPaymentMethods: this.allowPaymentMethods,
        });

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },
  watch: {},
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    steps() {
      const data = ["POS", "Informações"];
      return data;
    },
  },
  mounted() {
    this.$parent.$on("adopt-pos-modal", this.open);
  },
  props: {},
  created() {},
};
</script>

<style></style>
