<template>
  <div>
    <div class="d-flex align-center justify-end mb-2">
      <v-btn @click="addPos" color="primary">
        <v-icon left>mdi-plus</v-icon> vincular
      </v-btn>
    </div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="mt-2"
    />
    <v-alert v-else-if="!loading && error" type="error">{{ error }}</v-alert>

    <v-row>
      <v-col
        v-for="session in sessions"
        :key="session.Device.hash"
        cols="12"
        md="6"
        lg="4"
        class="mb-2"
      >
        <v-card class="pa-3" rounded="lg" outlined>
          <div class="d-flex gap-2">
            <v-img
              v-if="session.Device.Model?.image"
              :src="
                (session.Device?.acquirer &&
                  session.Device?.Model?.imageVariants?.[
                    session?.Device?.acquirer
                  ]) ||
                session.Device.Model.image
              "
              max-height="60"
              max-width="60"
            />
            <v-icon v-else color="primary" size="60">
              mdi-cash-register
            </v-icon>
            <div class="d-flex flex-grow-1 flex-column justify-center">
              <div>
                <v-btn text @click="editPos(session)" class="float-right" small>
                  <v-icon small left>mdi-pencil</v-icon>
                  Editar
                </v-btn>
                <h6 class="mb-0">{{ session.Address?.name }}</h6>
              </div>
              <span>SN: {{ session.Device.serialNumber }}</span>
            </div>
          </div>
          <div class="d-flex gap-2 mt-1">
            <v-btn
              color="primary"
              small
              class="flex-grow-1"
              @click="adoptPos(session)"
            >
              Relatório
            </v-btn>
            <v-btn
              color="primary"
              small
              class="flex-grow-1"
              @click="cashoutPos(session)"
            >
              Sangria
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <cashout-pos @success="getPos" />
    <adopt-pos @success="getPos" />
    <edit-adopt-pos @success="getPos" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";

import CashoutPos from "./modal/CashoutPos.vue";
import AdoptPos from "./modal/AdoptPos.vue";
import EditAdoptPos from "./modal/EditAdoptPos.vue";

export default {
  components: { AdoptPos, EditAdoptPos,CashoutPos },
  data: () => ({
    sessions: [],
    loading: true,
    error: false,
  }),

  methods: {
    async getPos() {
      this.loading = true;
      try {
        const orgId = this.selectedOrganization.id;
        const { sessions } = await POS.getAll(orgId);
        this.sessions = sessions;
      } catch (error) {
        this.error = error.message || "Erro ao buscar os POS";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async addPos() {
      this.$emit("adopt-pos-modal");
    },
    async editPos(pos) {
      this.$emit("edit-adopt-pos-modal", pos);
    },
    async cashoutPos(pos) {
      this.$emit("cashout-pos-modal", pos);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.getPos();
    if (this.$route.query.code) {
      this.$emit("adopt-pos-modal", this.$route.query.code);
      // clear query
      this.$router.replace({ query: {} });
    }
  },
};
</script>
