<template>
  <v-dialog
    v-model="isOpen"
    width="500"
    :persistent="!cashoutReceipt"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card
      v-if="!cashoutReceipt"
      class="h-full d-flex flex-column pa-2"
      style="max-height: 90dvh"
    >
      <v-card class="ma-4 pa-3" outlined>
        <div>
          <p class="text-13 text-center mb-0">Valor Disponivel</p>
          <div class="d-flex justify-center">
            <span class="text-22 font-weight-bold">
              {{ availableCashout | currency }}
            </span>
          </div>
        </div>
      </v-card>
      <template v-if="availableCashout != 0">
        <div class="px-4 mb-0 pb-0">
          <v-text-field
            v-model="selectedCashout"
            hide-details="auto"
            label="Valor da Sangria:"
            outlined
            dense
            type="number"
            :max="availableCashout"
            :rules="[
              (v) => !!v || 'Campo obrigatório',
              (v) => v <= availableCashout || 'Valor maior que o disponível',
            ]"
            min="0"
          />
        </div>
        <div class="d-flex justify-center">
          <v-btn
            color="info"
            block
            :disabled="selectedCashout == availableCashout"
            text
            :loading="loading"
            @click="selectedCashout = availableCashout"
          >
            Sangrar Tudo
          </v-btn>
        </div>
        <v-alert
          :color="selectedCashout == availableCashout ? 'success' : 'info'"
          text
          class="mt-8 mx-4 mb-0"
          dense
        >
          {{ formattedValue }}
        </v-alert>
        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>
      </template>
      <v-alert v-else color="error" text class="mx-4 mb-0" dense>
        Nenhum valor disponível para sangria
      </v-alert>
      <v-card-actions class="d-flex align-end gap-1 justify-space-between">
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="success"
          :disabled="this.availableCashout === 0"
          @click="cashout"
        >
          Sangrar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="text-h6 justify-space-between"
        >Recibo de Sangria
        <span>{{ cashoutReceipt?.id | shortId }}</span>
      </v-card-title>
      <v-card-text>
        <v-card outlined class="pa-2 mb-3 rounded-lg">
          <p class="lh-1 mb-0">Ponto de venda</p>
          <span class="text-15 font-weight-bold">
            {{ session?.Address?.name }}
          </span>
          <p class="mb-0 text-11">SN: {{ session?.Device?.serialNumber }}</p>
        </v-card>
        <v-card outlined class="pa-2 rounded-lg">
          <div class="d-flex gap-2 align-center">
            <base-avatar size="30" :src="user?.photo" :seed="user?.id" />
            <div>
              <p class="lh-1 mb-0">Responsável</p>
              <span class="text-15 font-weight-bold">
                {{ user.name }}
              </span>
            </div>
          </div>
        </v-card>
        <div class="d-flex align-center mt-4 mb-2">
          <span class="text-15 font-weight-bold pr-2"> Detalhes </span>
          <v-divider></v-divider>
        </div>
        <v-card outlined class="pa-3 rounded-lg">
          <div class="d-flex justify-space-between align-center">
            <p class="text-13 text-center mb-0">Valor Disponivel</p>
            <span class="text-13 font-weight-bold">
              {{ availableCashout | currency }}
            </span>
          </div>
          <div class="d-flex justify-space-between align-center">
            <p class="text-13 text-center mb-0">Valor Restante</p>
            <span class="text-13 font-weight-bold">
              {{ (availableCashout - selectedCashout) | currency }}
            </span>
          </div>
          <div class="d-flex justify-space-between align-center">
            <p class="text-13 text-center mb-0">Valor Sangrado</p>
            <span class="text-13 font-weight-bold">
              <v-chip  color="green" text-color="white">
                {{ selectedCashout | currency }}
              </v-chip>
            </span>
          </div>
        </v-card>
        <v-alert type="success" class="mb-0 mt-3" dense
          ><span class="text-15"
            >Sangria realizada com sucesso em
            {{ cashoutReceipt?.createdAt | date("DD/MM/YYYY HH:mm") }}</span
          >
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import Scanner from "../../../global/scanner/Scanner.vue";
import PlaceSearch from "../../../../views/global/PlaceSearch.vue";
import PhoneInput from "../../../global/PhoneInput.vue";

export default {
  components: {
    Scanner,
    PlaceSearch,
    PhoneInput,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    session: null,
    availableCashout: null,
    selectedCashout: null,
    cashoutReceipt: null,
  }),

  methods: {
    open(session) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.session = session;
      this.cashoutReceipt = null;
      this.cashoutValue();
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
    },
    formatCurrency(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    async cashoutValue() {
      try {
        const data = await POS.cashoutVerify(
          this.selectedOrganization.id,
          this.session.id
        );
        this.availableCashout = data.availableCashout;
        this.selectedCashout = data.availableCashout;
      } catch (error) {
        this.error = error.message || "Erro ao buscar o valor disponível";
      }
    },
    async cashout() {
      try {
        this.loading = true;
        const response = await POS.cashout(
          this.selectedOrganization.id,
          this.session.id,
          parseFloat(this.selectedCashout)
        );
        this.cashoutReceipt = response.cashout;
      } catch (error) {
        this.error = error.message || "Erro ao realizar a sangria";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {},
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["user"]),
    steps() {
      const data = ["POS", "Informações"];
      return data;
    },
    formattedValue() {
      if (this.selectedCashout === this.availableCashout) {
        return (
          "Valor total selecionado: " +
          this.formatCurrency(this.availableCashout)
        );
      } else {
        const remainingValue =
          this.availableCashout - parseFloat(this.selectedCashout || 0);
        return "Valor restante: " + this.formatCurrency(remainingValue);
      }
    },
  },
  mounted() {
    this.$parent.$on("cashout-pos-modal", this.open);
  },
  props: {},
  created() {},
};
</script>

<style></style>
