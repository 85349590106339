<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-text class="ma-0 pa-6">
        <v-card
          class="d-flex gap-4 align-center pa-2 mb-4"
          outlined
          rounded="lg"
        >
          <v-img
            v-if="device?.Model?.image"
            :src="
              (device?.acquirer &&
                device?.Model?.imageVariants?.[device.acquirer]) ||
              device?.Model?.image
            "
            max-height="55"
            max-width="55"
          />
          <v-icon v-else color="primary" size="55"> mdi-cash-register </v-icon>
          <div>
            <h6 class="mb-0">{{ address?.name }}</h6>
            SN: {{ device?.serialNumber }}
          </div>
        </v-card>

        <h6 class="mb-0">Formas de pagamento</h6>
        <div class="d-flex gap-4">
          <v-checkbox
            v-model="allowPaymentMethods"
            label="Dinheiro"
            value="money"
          />
          <v-checkbox
            v-model="allowPaymentMethods"
            label="PIX"
            value="pix"
          />
          <v-checkbox
            v-model="allowPaymentMethods"
            label="Cartão de Crédito"
            value="credit"
          />
          <v-checkbox
            v-model="allowPaymentMethods"
            label="Cartão de Débito"
            value="debit"
          />
        </div>
        <div class="mb-4 d-flex justify-space-between align-center">
          <h6 class="mb-0">Operadores</h6>
          <v-btn small @click="addUser" class="ml-2" color="primary">
            <v-icon small left>mdi-plus</v-icon>
            Operador
          </v-btn>
        </div>
        
        <p class="text-center text-secondary py-2" v-if="!users.length">Ao menos um operador é obrigatório</p>

        <div v-for="(user, i) in users" :key="i" class="d-flex">
          <phone-input
            :phone="user.phone"
            @update:phone="(e) => setUsers(i, 'phone', e)"
            :ddi="user.ddi"
            @update:ddi="(e) => setUsers(i, 'ddi', e)"
            @valid="(v) => setUsers(i, 'valid', v)"
            dense
            outlined
            class="flex-grow"
            label="WhatsApp do operador"
            :disabled="loading"
          />

          <v-btn @click="removeUser(i)" class="ml-2" icon>
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>

        <v-alert v-if="users.length" color="primary" dense type="info" text>
          Os operadores que ainda não possuem uma conta precisarão criar uma
          para usar o POS.
        </v-alert>

        <v-alert v-if="error" type="error" class="mx-6 mb-0">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1 justify-space-between">
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="success"
          :disabled="!users.every((u) => u.valid) || !address || !users.length"
          :loading="loading"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import PhoneInput from "../../../global/PhoneInput.vue";

export default {
  components: {
    PhoneInput,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    code: null,
    device: null,
    session: null,
    users: [],
    error: false,
    step: 1,
    address: null,
    allowPaymentMethods: ["money", "pix","credit","debit"],
  }),

  methods: {
    open(session) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.users = session.Users;
      this.allowPaymentMethods = session.allowPaymentMethods || [];
      this.address = session.Address;
      this.device = session.Device;
      this.session = session;
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.address = null;
      this.users = [{ phone: "", ddi: 55, valid: false }];
      this.allowPaymentMethods = ["online", "offline"];
    },
    setUsers(index, key, value) {
      this.users[index][key] = value;
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },

    addUser() {
      this.users.push({ phone: "", ddi: 55, valid: false });
    },

    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;

        await POS.editPos(orgId, this.session.id, {
          users: this.users,
          allowPaymentMethods: this.allowPaymentMethods,
        });

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },
  watch: {},
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("edit-adopt-pos-modal", this.open);
  },
  props: {},
  created() {},
};
</script>

<style></style>
